
html {
  -webkit-box-sizing: border-box;
       box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
      box-sizing: inherit;
}

html,body,#root {
  height: 100%;
  width: 100%;
  overflow: hidden
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar on mobile */
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  * {
    scrollbar-width: none; /* Hides scrollbar in Firefox */
  }
}

/* Default scrollbar for larger screens */
@media (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d0d0d0;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #d0d0d0 transparent;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSelect-icon{
  position: relative !important;
}

.zoomable-image {
  cursor: grab;
}

.zoomable-image:active {
  cursor: grabbing;
}
svg > g{
  padding-left: 2em;
}

.MuiTablePagination-input{
  background-color: white !important;
}

.MuiChartsAxis-tickLabel {
  fill: #808080B0 !important;
  font-size: 10px !important
  
}
.MuiBarLabel-root {
  fill: white !important;
}

.MuiChartsAxis-line{
  stroke: #808080B0 !important;

}

.filter-input-grid-item input {
  font-size: 12px;
  padding: 0px;
}

.filter-input-grid-item .MuiInputBase-root {
  padding: 0.5em;
}
